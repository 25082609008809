import React, { Component } from 'react';
import MaterialNavBar from './MaterialNavBar';
import Footer from './Footer';
import { MDBContainer } from 'mdb-react-ui-kit';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className="vh-100">
                <MaterialNavBar />
                <MDBContainer fluid className='App'>{this.props.children}</MDBContainer>
                <Footer />
            </div>
        );
    }
}
