import React from "react";
import { ClipLoader } from "react-spinners";
import { ProductCard } from "./ProductCard";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

class ProductsList extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading: true,
            solutions: []
        }

        fetch('https://api.automationtestkit.com', {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => this.setState({ solutions: data, loading: false }));
    }

    componentDidMount() {
        document.title = "Automation Test Kit | Products"
    }

    render() {
        return (
            <div>
            { this.state.loading ? <ClipLoader className='centered' loading={this.state.loading} /> : null }
            <MDBRow>
            { this.state.loading ? null : this.state.solutions.filter(solution => !solution.isHidden).map(solution => (<MDBCol sm='6'><ProductCard product={solution} /></MDBCol>))}
            </MDBRow>
            </div>
        )
    }
}

export default ProductsList;