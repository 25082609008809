import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCardTitle } from 'mdb-react-ui-kit';
import { ClipLoader } from "react-spinners";

export class Contact extends Component {
    static displayName = Contact.name;

    constructor(props) {
        super(props);
        this.state = { loading: false }
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        document.title = "Automation Test Kit | Contact Us"
    }

    async submit() {
        var firstName = document.getElementById("firstName").value;
        var lastName = document.getElementById("lastName").value;
        var email = document.getElementById("email").value;
        var message = document.getElementById("message").value;

        this.setState({ loading: true });

        await this.createTicket(firstName, lastName, email, message);

        this.setState({ loading: false });
    }

    async createTicket(firstName, lastName, email, message) {
        var contactObj = {
            "firstName": firstName,
            "lastName": lastName,
            "emailAddress": email,
            "message": message
        };

        await fetch('https://api.automationtestkit.com/submitQuery', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(contactObj)
        })
        .then(response => response.json())
        .then(data => {
            this.setState({ contactId: data });
        })
        .catch(error => {
            this.setState({ loading: false });
        });
    }

    render() {
        return (
            <MDBContainer className='white-text'>
                { this.state.loading ? <ClipLoader className='centered' loading={this.state.loading} /> :
                <Form>
                    <MDBRow>
                        <MDBCol>
                            <MDBCardTitle>Contact Us</MDBCardTitle>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control id="firstName" />
                            </Form.Group>
                        </MDBCol>
                        <MDBCol>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control id="lastName" />
                            </Form.Group>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control id="email" type="email" />
                        </Form.Group>
                    </MDBRow>
                    <MDBRow>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" id="message" rows={3} />
                        </Form.Group>
                    </MDBRow>
                    <MDBBtn onClick={this.submit} style={{ width: '100%' }}>Submit</MDBBtn>
                </Form>}
            </MDBContainer>
        );
    }
}
