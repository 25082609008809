import React, { Component } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';

export class AddProduct extends Component {
    static displayName = AddProduct.name;

    constructor(props) {
        super(props);
        this.state = { loading: false }
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        document.title = "Automation Test Kit | New Product"
    }

    async submit() {
        var productName = document.getElementById("productName").value;
        var productDesc = document.getElementById("productDesc").value;
        var productURL = document.getElementById("productURL").value;
        var password = document.getElementById("password").value;

        this.setState({ loading: true });

        await this.createProduct(password, productName, productDesc, productURL);

        this.setState({ loading: false });
    }

    async createProduct(password, productName, productDesc, productURL) {

        let id = crypto.randomUUID();

        var properties = {
            "id": id,
            "name": productName,
            "description": productDesc,
            "stripeUrl": productURL
        };

        await fetch('https://api.automationtestkit.com', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"password": password, "type": "product", "payload": properties})
        })
        .then(response => response.json())
        .then(data => {
            this.setState({ contactId: data });
        })
        .catch(error => {
            this.setState({ loading: false });
        });
    }

    render() {
        return (
            <Container className='white-text'>
                {this.state.loading ? <ClipLoader className='centered' loading={this.state.loading} /> : <Form>
                    <Row>
                        <Col>
                            <h2>New Product</h2>
                        </Col>
                    </Row>
                    <Row>
                            <Form.Group className="mb-3">
                                <Form.Label>Product Name</Form.Label>
                                <Form.Control id="productName" />
                            </Form.Group>
                    </Row>
                    <Row>
                            <Form.Group>
                                <Form.Label>Product Description</Form.Label>
                                <Form.Control as="textarea" id="productDesc" rows={3} />
                            </Form.Group>
                    </Row>
                    <br />
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Product Purchase (Stripe) URL</Form.Label>
                            <Form.Control id="productURL" />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control id="password" type="password" />
                        </Form.Group>
                    </Row>
                    <Button onClick={this.submit} style={{ width: '100%' }}>Submit</Button>
                </Form>}
            </Container>
        );
    }
}
