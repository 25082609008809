import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardFooter, MDBCardText, MDBCardTitle } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";

export class ProductCard extends Component {
  static displayName = ProductCard.name;

  constructor(props) {
    super(props);
    this.state = { product: null }
    this.state.product = props.product;
  }

  render() {
    return (
      <MDBCard>
        <MDBCardHeader>
          <MDBCardTitle><i className='fas fa-file me-3'></i>{this.state.product.name}</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBCardText>
            {this.state.product.subDescription}
          </MDBCardText>
          <MDBCardText>
            <Link to={'/products/' + this.state.product.id}>Learn More</Link>
          </MDBCardText>
        </MDBCardBody>
        <MDBCardFooter>
          <small className="text-muted">{this.state.product.price} / license</small>
        </MDBCardFooter>
      </MDBCard>
    );
  }
}
