import React, { Component } from 'react';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <MDBContainer>
                    <h1 className="display-3">A new generation of automated testing capability</h1>
                    <p className="lead">Empower your testing, simplify development and offload the cost.</p>
                    <hr className="my-2" />
                    <p>Our Selenium libraries allow you to significantly speed up development, thanks to our simple usage patterns and continuous support.</p>
                    <p>Testing an application built with Bootstrap? Our Bootstrap TestKit library provides a range of Selenium selectors. <br />
                    Building and maintaining code for simple Bootstrap selectors is no longer your problem</p>
                    <p className="lead">
                        <MDBBtn color="primary" onClick={ () => window.location.href="/products" }>Learn More</MDBBtn>
                    </p>
            </MDBContainer>
        );
    }
}
