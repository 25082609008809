import './custom.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Home } from './components/Home';
import { Contact } from './components/Contact';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import ProductsList from './components/products/ProductsList';
import ViewProduct from './components/products/ViewProduct';
import { AddProduct } from './components/products/AddProduct';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter>
  <Routes>
      <Route path='/' element={<App />}>
          <Route index element={<Home />} />
          <Route path='contact-us' element={<Contact />} />
          <Route path='products'>
              <Route index element={<ProductsList />} />
              <Route path='new' element={<AddProduct />} />
              <Route path=':productId'>
                <Route index element={<ViewProduct />} />
              </Route>
          </Route>
      </Route>
  </Routes>
</BrowserRouter>,
  rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
