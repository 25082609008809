import { React, useState } from "react";
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

export default function ViewProduct() {
    let { productId } = useParams();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState({});

    if (loading) {
        fetch('https://api.automationtestkit.com', {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => setProduct(data.find(obj => { return obj.id === productId })))
            .then(obj => setLoading(false));

        document.title = 'Automation Test Kit | ' + product.name;
    }

    return (
        loading ? <ClipLoader className='centered' loading={loading} /> :
            <MDBContainer>
                <p dangerouslySetInnerHTML={{ __html: product.description }} />
                <MDBBtn disabled={product.isDisabled} style={{ width: '100%' }} variant="primary" onClick={() => window.location.href = product.stripeUrl}>{product.buttonText}</MDBBtn>
            </MDBContainer>
    )
}